<script lang="ts" setup>
import { useDisplay } from 'vuetify'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore avoid ts error
import { useAVWaveform, type CreateFetchOptions } from 'vue-audio-visual'

/* AudioPlayerWaveform works with :
  - AudioPlayer.vue component
  - useAudioPlayer composable

FEATURES:
- Support most of audio play in this component.
- Support download the audio file.
- After audio playing finished or before start the playing, you can do something.
- Support Dark mode of Vuetify.
- Support auto play, chaining play, next and previous play
- Support turn on and off audio download button.
- You can set custom icons supported by vuetify v-icon component.

open audio player:
  playAudioDemo({
                  demos: item.raw.demos,
                  name: item.raw.name,
                  image: item.raw.image,
                })
*/

const props = withDefaults(
  defineProps<{
    autoPlay?: boolean
    chainPlay?: boolean
    downloadable?: boolean
    playIcon?: string
    pauseIcon?: string
    stopIcon?: string
    prevIcon?: string
    nextIcon?: string
    refreshIcon?: string
    downloadIcon?: string
    volumeHighIcon?: string
    volumeMuteIcon?: string
    volume?: number
    ended?: () => void
    canPlay?: () => void
  }>(),
  {
    autoPlay: true,
    chainPlay: true,
    downloadable: true,
    playIcon: 'mdi-play',
    pauseIcon: 'mdi-pause',
    stopIcon: 'mdi-stop',
    prevIcon: 'mdi-skip-previous',
    nextIcon: 'mdi-skip-next',
    refreshIcon: 'mdi-refresh',
    downloadIcon: 'mdi-download',
    volumeHighIcon: 'mdi-volume-high',
    volumeMuteIcon: 'mdi-volume-mute',
    volume: 0.8,
  },
)

const { mobile } = useDisplay()
const audioPlayer = useAudioPlayer()
const { downloadFile } = useAudioPlayer()

const audio = ref<HTMLAudioElement | null>(null)
const canvas = ref<HTMLCanvasElement | null>(null)

const firstPlay = ref(true)
const isMuted = ref(false)
const volumeIcon = ref(props.volumeHighIcon)
const loaded = ref(false)
const playing = ref(false)
const paused = ref(false)
const currentTime = ref('0:00')

const totalDuration = ref(0)
const playerVolume = ref(props.volume)

function formatTime(second: number) {
  return new Date(second * 1000).toISOString().substring(15, 19)
}

const duration = computed(() => {
  return audio.value ? formatTime(totalDuration.value) : ''
})

const previousActive = computed(() => {
  if (audioPlayer.playingIndex > 1) return true
  return false
})

const nextActive = computed(() => {
  if (audioPlayer.audioPlayerData.demos) {
    if (audioPlayer.playingIndex < audioPlayer.audioPlayerData.demos.length) return true
  }
  return false
})

function previous() {
  // console.info('event:previous')
  audioPlayer.playingIndex = audioPlayer.playingIndex - 1
}

function next() {
  // console.info('event:next')
  audioPlayer.playingIndex = audioPlayer.playingIndex + 1
}

function stop() {
  // console.info('event:stop')
  if (audio.value) {
    audio.value.pause()
    paused.value = true
    playing.value = false
    audio.value.currentTime = 0
  }
}

async function play() {
  // console.info('event:play')
  if (audio.value) {
    if (playing.value) return
    await audio.value.play()
    playing.value = true
    paused.value = false
  }
}

function pause() {
  // console.info('event:pause')
  if (audio.value) {
    paused.value = !paused.value
    paused.value ? audio.value.pause() : audio.value.play()
  }
}

async function download() {
  // console.info('event:download')
  if (audio.value) {
    audio.value.pause()
    await downloadFile()
  }
}

function mute() {
  if (audio.value) {
    isMuted.value = !isMuted.value
    volumeIcon.value = isMuted.value ? props.volumeMuteIcon : props.volumeHighIcon
    audio.value.muted = isMuted.value
  }
}

function reload() {
  // console.info('event:reload')
  if (audio.value) {
    audio.value.load()
  }
}

function handleLoaded() {
  // console.info('event:handleLoaded')
  if (audio.value) {
    if (audio.value.readyState >= 2) {
      if (audio.value.duration === Infinity) {
        // Fix duration for streamed audio source or blob based
        // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
        audio.value.currentTime = 1e101
        audio.value.ontimeupdate = () => {
          if (!audio.value) throw new Error('audio player ref is undefined')
          audio.value.ontimeupdate = () => {
            // console.info('ontimeupdate')
          }
          audio.value.currentTime = 0
          totalDuration.value = audio.value.duration
          loaded.value = true
        }
      } else {
        totalDuration.value = audio.value.duration
        loaded.value = true
      }

      if (props.autoPlay) audio.value.play()
    } else {
      throw new Error('Failed to load sound file')
    }
  }
}

function handlePlayingUI(e: Event) {
  // console.info('event:handlePlayingUI')
  if (audio.value) {
    audio.value.volume = playerVolume.value
    currentTime.value = formatTime(audio.value.currentTime)
    playing.value = true
  }
}

function handlePlayPause(e: Event) {
  // console.info('event:handlePlayPause')
  if (audio.value) {
    if (e.type === 'play' && firstPlay.value) {
      // in some situations, audio.currentTime is the end one on chrome
      audio.value.currentTime = 0
      if (firstPlay.value) {
        firstPlay.value = false
      }
    }
    if (e.type === 'pause' && paused.value === false && playing.value === false) {
      currentTime.value = '00:00:00'
    }
  }
}

function handleEnded() {
  // console.info('event:handleEnded')
  if (audioPlayer.audioPlayerData.demos) {
    if (
      typeof props.chainPlay !== 'undefined' &&
      props.chainPlay &&
      audioPlayer.playingIndex < audioPlayer.audioPlayerData.demos.length
    ) {
      audioPlayer.playingIndex = audioPlayer.playingIndex + 1
    } else {
      paused.value = playing.value = false
    }
  }
}

function init() {
  if (audio.value) {
    // console.log('init')
    audio.value.addEventListener('timeupdate', handlePlayingUI)

    // workaround for iOS : HTML5 Video loadeddata event does not work in IOS Safari, https://www.youtube.com/watch?v=h1ER9DsZ0FY
    if (/iPhone|iPod/.test(navigator.userAgent)) audio.value.autoplay = true

    audio.value.addEventListener('loadeddata', handleLoaded)
    audio.value.addEventListener('pause', handlePlayPause)
    audio.value.addEventListener('play', handlePlayPause)
    audio.value.addEventListener('ended', handleEnded)
  }
}

onMounted(() => {
  // console.log('onMounted')
  init()
})

onBeforeUnmount(() => {
  // console.log('onBeforeUnmount')
  if (audio.value) {
    audio.value.removeEventListener('timeupdate', handlePlayingUI)
    audio.value.removeEventListener('loadeddata', handleLoaded)
    audio.value.removeEventListener('pause', handlePlayPause)
    audio.value.removeEventListener('play', handlePlayPause)
    audio.value.removeEventListener('ended', handleEnded)
  }
})

const waveformWidth = 430
const waveformWidthMobile = 330
const useAVWaveformCommonProps = {
  canvHeight: 40,
  playedLineWidth: 2,
  playedLineColor: '#03a9f4',
  noplayedLineWidth: 0.3,
  noplayedLineColor: '#f066f0',
  playtimeSliderColor: '#f066f0',
  playtimeSliderWidth: 2,
  playtimeFontColor: '#03a9f4',
  playtimeFontFamily: '',
  playtime: false,
  playtimeWithMs: false,
}
const useAVWaveformFetchOptions: CreateFetchOptions = {
  options: { refetch: true },
  fetchOptions: { mode: 'cors' },
}

watch(
  () => audioPlayer.playingUrl,
  (url) => {
    stop()
    useAVWaveform(
      audio,
      canvas,
      {
        src: url,
        canvWidth: waveformWidth,
        ...useAVWaveformCommonProps,
      },
      useAVWaveformFetchOptions,
    )
  },
  { immediate: true },
)

watch(
  mobile,
  (v) => {
    if (mobile.value) {
      useAVWaveform(
        audio,
        canvas,
        {
          src: audioPlayer.playingUrl,
          canvWidth: waveformWidthMobile,
          ...useAVWaveformCommonProps,
        },
        useAVWaveformFetchOptions,
      )
    } else {
      useAVWaveform(
        audio,
        canvas,
        {
          src: audioPlayer.playingUrl,
          canvWidth: waveformWidth,
          ...useAVWaveformCommonProps,
        },
        useAVWaveformFetchOptions,
      )
    }
  },
  { immediate: true },
)
</script>

<template>
  <div style="text-align: center">
    <audio
      id="player"
      ref="audio"
      :src="audioPlayer.playingUrl"
      @ended="ended"
      @canplay="canPlay"
    ></audio>
    <div style="display: flex; flex-direction: row">
      <div>
        <VImg
          v-if="audioPlayer.audioPlayerData.image"
          :src="audioPlayer.audioPlayerData.image"
          class="metaImage"
          style="margin: 8px"
        />
      </div>
      <div :style="mobile ? {} : { 'margin-left': '24px' }">
        <div style="white-space: nowrap">
          <VBtn
            icon
            size="40"
            class="ma-2 rounded-circle"
            :disabled="!loaded || !previousActive"
            @click="previous()"
          >
            <VIcon :icon="props.prevIcon" />
          </VBtn>
          <VBtn
            icon
            size="40"
            class="ma-2 rounded-circle"
            :disabled="!loaded"
            @click="playing ? pause() : play()"
          >
            <VIcon v-if="!playing || paused" :icon="props.playIcon" />
            <VIcon v-else :icon="props.pauseIcon" />
          </VBtn>
          <VBtn icon size="40" class="ma-2 rounded-circle" :disabled="!loaded" @click="stop()">
            <VIcon :icon="props.stopIcon" />
          </VBtn>
          <VBtn
            icon
            size="40"
            class="ma-2 rounded-circle"
            :disabled="!loaded || !nextActive"
            @click="next()"
          >
            <VIcon :icon="props.nextIcon" />
          </VBtn>
          <!-- <VBtn
            v-if="!loaded && !mobile"
            icon
            size="40"
            class="ma-2 rounded-circle"
            @click="loaded ? download() : reload()"
          >
            <VIcon :icon="props.refreshIcon" />
          </VBtn> -->
          <VBtn
            v-if="loaded && downloadable && !mobile"
            icon
            size="40"
            class="ma-2 rounded-circle ml-13"
            @click="loaded ? download() : reload()"
          >
            <VIcon :icon="props.downloadIcon" />
          </VBtn>
        </div>
        <div
          style="display: flex; flex-direction: row; align-items: center; justify-content: center"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              margin-top: 0px;
            "
          >
            <VIcon :icon="volumeIcon" color="var(--dvn-color-primary)" @click="mute()" />
            <div>
              <VSlider
                v-model="playerVolume"
                max="1"
                step="0.01"
                min="0"
                :thumb-size="12"
                :track-size="3"
                :style="mobile ? { width: '100px' } : { width: '150px' }"
              />
            </div>
          </div>
          <div
            style="white-space: nowrap; width: 85px"
            :style="mobile ? { 'margin-left': '10px' } : { 'margin-left': '16px' }"
          >
            {{ currentTime }} /
            {{ duration }}
          </div>
        </div>
      </div>
    </div>
    <canvas ref="canvas"></canvas>
  </div>
</template>
<style lang="css" scoped>
.metaImage {
  height: 80px;
  width: 80px;
  /* border: 1px solid rgba(var(--v-theme-primary), 0.7); */
  border-radius: 4px;
}
</style>
